<template>
  <v-container id="SiteView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">Site List</div>
            <div class="text-subtitle-1 font-weight-medium">
              Total {{ total_sites }} sites
            </div>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            @click.prevent="navigateToCreateSite()"
          >
            <v-icon left> mdi-plus </v-icon>
            New Site
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <v-row class="py-0">
      <v-col cols="9">
        <div class="primary--text">Sort By</div>
      </v-col>
      <v-col cols="3">
        <v-text-field dense rounded label="Search" outlined></v-text-field>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12">
        <v-data-table
          class="elevation-1 cursor-pointer"
          disable-sort
          :headers="headers"
          :items="site_list"
          :items-per-page="10"
          :footer-props="{ 'disable-items-per-page': true }"
          @click:row="rowDataSelected"
          @pagination="paginationUpdated"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="requestRemoveExistingSite(item._id)"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span> Remove Site</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "SiteView",
  data() {
    return {
      headers: [
        { text: "ID", value: "_id" },
        {
          text: "Site Name",
          align: "start",
          value: "siteName",
        },
        //  { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState({
      site_list: (state) => state.site.site_list,
      total_sites: (state) => state.site.total_sites,
      current_page: (state) => state.site.current_page,
    }),
  },
  methods: {
    ...mapActions({
      requestGetSiteList: "requestGetSiteList",
      requestRemoveExistingSite: "requestRemoveExistingSite",
    }),
    ...mapMutations({
      resetSiteState: "RESET_SITE_STATE",
      setFilterPage: "SET_SITE_FILTER_PAGE",
    }),
    rowDataSelected(row) {
      this.navigateToSiteDetail(row._id);
    },
    paginationUpdated(pagination) {
      if (
        pagination.page === pagination.pageCount &&
        pagination.pageStop < this.total_sites
      ) {
        this.setFilterPage(this.current_page + 1);
        this.requestGetSiteList();
      }
    },
    navigateToCreateSite() {
      this.$router.push({ name: "site-create" });
    },
    navigateToSiteDetail(site_id) {
      this.$router.push({ name: "site-detail", params: { id: site_id } });
    },
  },
  async beforeMount() {
    await this.requestGetSiteList();
  },
  beforeRouteLeave(to, from, next) {
    this.resetSiteState();
    next();
  },
};
</script>
